export const PREPARED_BY_MYSELF = 'myself';
export const PREPARED_BY_ADVISOR = 'advisor';
export const PREPARED_BY_COMPANY = 'company';
// const PREPARED_BY_TEAM = 'team';

export const PREPARED_BY_OPTIONS = [
  { label: 'Myself', value: PREPARED_BY_MYSELF },
  { label: 'Advisor', value: PREPARED_BY_ADVISOR },
  { label: 'Company', value: PREPARED_BY_COMPANY }
  // { label: 'Team', value: PREPARED_BY_TEAM },
];

const PREPARED_FOR_ACCOUNT = 'account';
const PREPARED_FOR_INVESTOR = 'investor';
const PREPARED_FOR_HOUSEHOLD = 'household';
export const PREPARED_FOR_CUSTOM = 'custom';

export const PREPARED_FOR_OPTIONS = [
  { label: 'Account(s) name(s)', value: PREPARED_FOR_ACCOUNT },
  { label: 'Investor name', value: PREPARED_FOR_INVESTOR },
  { label: 'Household name', value: PREPARED_FOR_HOUSEHOLD },
  { label: 'Custom name', value: PREPARED_FOR_CUSTOM }
];
