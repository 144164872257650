import { InputButton, VerboseErrorInput } from 'components/form';
import { AdvisorContext } from 'containers/advisor';
import { AuthenticationContext } from 'containers/auth';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import { Link } from 'react-router';
import 'react-table/react-table.css'; // Import CSS for react-table
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { COMPANY_STATUS_LIST, PRODUCT_TYPE_LIST, HUBSPOT, SEARCH_HUBSPOT } from './constants';
import SelectWrapper from './selectWrapper';
import './styles.scss';
import { GetHubspotLink } from './staff-companies-list';
import Autosuggest from 'react-autosuggest';
import AdvisorTable from './advisors/list';

const DatePickerWrapper = props => {
  const { header, value } = props;

  const onDatePickerChange = date => {
    const { onChange } = props;
    onChange(date);
  };

  return (
    <div style={{ width: '228px', display: 'flex', flexFlow: 'column' }}>
      <label
        htmlFor={header.toLowerCase().replace(/\s/g, '-')}
        style={{
          fontSize: '16px',
          color: '#212529'
        }}
      >
        {header}
      </label>
      <div>
        <DatePicker
          required={false}
          minDate={new Date()}
          placeholderText="Select Date"
          value={value}
          onChange={onDatePickerChange}
          className="companies-datepicker"
        />
      </div>
    </div>
  );
};

const DisplayToast = (res, router) => {
  if (res.error) {
    const errorMsg = res.error.message;

    const errorsStr = Object.entries(res.error.errors)
      .map(([error, desc]) => `${error}: ${desc}`)
      .join('\n');

    toast.error(() => <div>{`${errorMsg}\n${errorsStr}`}</div>);
  }
  // successful notification
  else {
    toast.success(() => <div>Changes applied successfully.</div>);
    router.push('/advisor/companies');
  }
};

const EditableCompany = ({ params: { companyId }, handleSubmit, initializeForm, fields }) => {
  const { staffCompaniesProvider } = useContext(AdvisorContext);
  const { routerActions } = useContext(AuthenticationContext);
  const [hubSpotOptions, setHubSpotOptions] = React.useState([]);
  const [hubSpotSearch, setHubSpotSearch] = React.useState('');
  const [firstEditRender, setFirstEditRender] = React.useState(companyId !== 'create');

  useEffect(() => {
    if (companyId !== 'create')
      staffCompaniesProvider.get(companyId).then(res => {
        if (!res.error) {
          const {
            name,
            hubspot_id: hubspotId,
            hubspot_url: hubspotUrl,
            stripe_customer: stripeCustomer,
            trial_end: trialEnd,
            status,
            product,
            is_internal: isInternal
          } = res.data;
          setHubSpotSearch(hubspotId);
          setHubSpotOptions([{ id: hubspotId, name: hubspotId }]);
          initializeForm({
            companyName: name,
            hubSpot: {
              id: hubspotId ?? null,
              name: hubspotId ?? null
            },
            hubSpotUrl: hubspotUrl,
            stripeId: stripeCustomer,
            product,
            status,
            trialUntil: trialEnd ?? null,
            isInternal: !!isInternal
          });
        }
      });
  }, [companyId]);

  useEffect(() => {
    if (!hubSpotSearch) {
      setHubSpotOptions([]);
      return;
    }

    if (firstEditRender) {
      setFirstEditRender(false);
      return;
    }

    const handler = setTimeout(() => {
      fillHubSpot(hubSpotSearch);
    }, 500);

    return () => clearTimeout(handler);
  }, [hubSpotSearch]);

  const formSubmit = values => {
    const { companyName, hubSpot, stripeId, product, status, trialUntil, isInternal } = values;

    const company = {
      name: companyName,
      hubspot_id: hubSpot.id,
      stripe_customer: stripeId,
      trial_end: trialUntil,
      status,
      product,
      is_internal: isInternal
    };

    const action = companyId === 'create' ? 'create' : 'edit';
    if (companyId !== 'create') company.id = companyId;

    staffCompaniesProvider[action](company)
      .then(res => DisplayToast(res, routerActions))
      .catch(error => {
        toast.error(() => <div>{error.message}</div>);
      });
  };

  const fillHubSpot = search => {
    staffCompaniesProvider.searchHubSpot({ q: search }).then(data => {
      setHubSpotOptions(data.map(([id, name]) => ({ id, name })));
    });
  };

  const onHubSpotSelected = (_, { suggestion }) => {
    const { hubSpot } = fields;

    hubSpot.id.onChange(suggestion.id);
    hubSpot.name.onChange(suggestion.name);
    setHubSpotSearch(suggestion.name);
  };

  const onHubSpotChange = event => {
    const { value } = event.target;

    setHubSpotSearch(value);
  };

  return (
    <div className="staff-companies" style={{ marginTop: '26px' }}>
      <div className="h2">
        <h2>Company</h2>
      </div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div
          style={{
            background: 'white',
            padding: '35px',
            marginBottom: '30px',
            border: '1px solid #d3d6db'
          }}
        >
          <div className="form-row">
            <VerboseErrorInput
              {...fields.companyName}
              label="Name"
              type="text"
              name="companyName"
              placeholder="Company Name"
              className="form-control"
            />
            <div>
              <span className="span-label">{HUBSPOT}</span>
              <Autosuggest
                className="form-control"
                suggestions={hubSpotOptions}
                getSuggestionValue={s => s.name}
                renderSuggestion={s => (
                  <span>
                    <span className="truncate-name">{s.name}</span> ({s.id})
                  </span>
                )}
                onSuggestionSelected={onHubSpotSelected}
                onSuggestionsFetchRequested={({ value }) => {
                  setHubSpotSearch(value);
                }}
                onSuggestionsClearRequested={() => setHubSpotOptions([])}
                inputProps={{
                  type: 'search',
                  className: 'form-control',
                  onChange: onHubSpotChange,
                  placeholder: SEARCH_HUBSPOT,
                  value: hubSpotSearch
                }}
                theme={{
                  container: 'react-autosuggest__container',
                  containerOpen: 'open react-autosuggest__container--open',
                  suggestionsContainer: 'dropdown-menu react-autosuggest__suggestions-container',
                  suggestion: 'dropdown-item-new react-autosuggest__suggestion'
                }}
              />
              <div
                style={{
                  marginTop: '5px',
                  display: 'flex'
                }}
              >
                {GetHubspotLink(fields?.hubSpotUrl?.value)}
              </div>
            </div>
            <VerboseErrorInput
              {...fields.stripeId}
              label="Stripe ID"
              type="text"
              name="stripeId"
              placeholder="cs_123456789"
              className="form-control"
            />
          </div>
          <div className="form-row">
            <SelectWrapper
              styles={{ width: '228px' }}
              {...fields.product}
              header="Product"
              options={PRODUCT_TYPE_LIST}
              className="dropdown"
            />
            <SelectWrapper
              styles={{ width: '228px' }}
              {...fields.status}
              header="Status"
              options={COMPANY_STATUS_LIST}
              className="dropdown"
            />
            <DatePickerWrapper {...fields.trialUntil} header="Trial Until" />
          </div>
          <div className="form-row">
            <InputButton
              option={{ label: 'Demo/Testing' }}
              styles={{ display: 'flex' }}
              multiple
              checked={fields.isInternal.value}
              field={fields.isInternal}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link to="/advisor/companies">
            <button type="button" className="btn btn-outline-primary">
              Cancel
            </button>
          </Link>
          <button type="submit" className="btn btn-primary" style={{ height: '38px' }}>
            Save
          </button>
        </div>
      </form>
      {companyId !== 'create' && (
        <>
          <hr />
          <AdvisorTable companyId={companyId} />
        </>
      )}
    </div>
  );
};

EditableCompany.propTypes = {
  params: PropTypes.shape({
    companyId: PropTypes.string.isRequired
  }).isRequired
};

DatePickerWrapper.propTypes = {
  header: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired
};

EditableCompany.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    companyName: PropTypes.object.isRequired,
    hubSpot: PropTypes.object.isRequired,
    hubSpotUrl: PropTypes.object.isRequired,
    stripeId: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    status: PropTypes.object.isRequired,
    trialUntil: PropTypes.object.isRequired,
    isInternal: PropTypes.object.isRequired
  }).isRequired
};

export default reduxForm({
  form: 'editableStaffCompany', // a unique identifier for this form
  fields: [
    'companyName',
    'hubSpot.id',
    'hubSpot.name',
    'stripeId',
    'product',
    'status',
    'trialUntil',
    'hubSpotUrl',
    'isInternal'
  ],
  initialValues: {
    companyName: '',
    hubSpot: {
      id: '',
      name: ''
    },
    hubSpotUrl: '',
    stripeId: '',
    product: '',
    status: '',
    trialUntil: '',
    isInternal: false
  }
})(EditableCompany);
