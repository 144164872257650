import {
  STAFF_COMPANY_LIST,
  STAFF_COMPANY_CREATE,
  STAFF_COMPANY_GET,
  STAFF_COMPANY_EDIT,
  STAFF_COMPANY_ADVISOR_LIST,
  STAFF_COMPANY_ADVISOR_CREATE,
  STAFF_COMPANY_ADVISOR_GET,
  STAFF_COMPANY_ADVISOR_EDIT
} from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class StaffCompaniesProvider extends DataProvider {
  edit(company) {
    // PATCH '/api/staff/companies/:id'
    return this.provider
      .patch(`${config.apiBase}staff/companies/${company.id}/`, company)
      .then(data => {
        if (!data.error) this.dispatch(STAFF_COMPANY_EDIT, { data });
        return data;
      });
  }

  list(params = {}) {
    // GET '/api/staff/companies/'
    return this.provider.get(`${config.apiBase}staff/companies/`, params).then(response => {
      if (!response.error) {
        // eslint-disable-next-line no-underscore-dangle
        this._params = { ...params };
        this.dispatch(STAFF_COMPANY_LIST, {
          data: {
            data: response.data.results || [],
            meta: {
              params,
              count: response.data.count,
              next: response.data.next,
              previous: response.data.previous,
              total_pages: response.data.total_pages
            }
          }
        });
      } else this.dispatch(STAFF_COMPANY_LIST, { data: null });
      return response;
    });
  }

  get(id) {
    // GET '/api/advisors/:id'
    return this.provider.get(`${config.apiBase}staff/companies/${id}/`).then(data => {
      if (!data.error)
        this.dispatch(STAFF_COMPANY_GET, {
          data: data.data
        });
      return data;
    });
  }

  create(company) {
    // POST '/api/staff/companies/'
    return this.provider.post(`${config.apiBase}staff/companies/`, company).then(data => {
      if (!data.error) this.dispatch(STAFF_COMPANY_CREATE, { data });
      return data;
    });
  }

  delete(companyId) {
    // DELETE '/api/staff/companies/:company_id/'
    const promise = this.provider
      .deleteWithResponseBody(`${config.apiBase}staff/companies/${companyId}/`)
      .then(data => {
        if (!data.error) this.dispatch(STAFF_COMPANY_EDIT, { data: data.data });
        return data;
      });

    return promise;
  }

  searchHubSpot(query = {}) {
    // GET '/api/staff/companies/hubspot/'
    return this.provider
      .get(`${config.apiBase}staff/companies/hubspot/`, { ...query })
      .then(({ data }) => data);
  }

  listAdvisors(companyId, params = {}) {
    // GET '/api/staff/companies/:company_id/advisors/'
    return this.provider
      .get(`${config.apiBase}staff/companies/${companyId}/advisors/`, params)
      .then(response => {
        if (!response.error) {
          this._params = { ...params };
          this.dispatch(STAFF_COMPANY_ADVISOR_LIST, {
            data: {
              data: response.data.results || [],
              meta: {
                params,
                count: response.data.count,
                next: response.data.next,
                previous: response.data.previous,
                total_pages: response.data.total_pages
              }
            }
          });
        } else this.dispatch(STAFF_COMPANY_ADVISOR_LIST, { data: null });
        return response;
      });
  }

  createAdvisor(companyId, advisor) {
    // POST '/api/staff/companies/:company_id/advisors/'
    return this.provider
      .post(`${config.apiBase}staff/companies/${companyId}/advisors/`, advisor)
      .then(data => {
        if (!data.error) this.dispatch(STAFF_COMPANY_ADVISOR_CREATE, { data });
        return data;
      });
  }

  getAdvisor(companyId, advisorId) {
    // GET '/api/staff/companies/:company_id/advisors/:advisor_id/'
    return this.provider
      .get(`${config.apiBase}staff/companies/${companyId}/advisors/${advisorId}/`)
      .then(data => {
        if (!data.error)
          this.dispatch(STAFF_COMPANY_ADVISOR_GET, {
            data: data.data
          });
        return data;
      });
  }

  editAdvisor(companyId, advisor) {
    // PATCH '/api/staff/companies/:company_id/advisors/:advisor_id/'
    return this.provider
      .patch(`${config.apiBase}staff/companies/${companyId}/advisors/${advisor.id}/`, advisor)
      .then(data => {
        if (!data.error) this.dispatch(STAFF_COMPANY_ADVISOR_EDIT, { data });
        return data;
      });
  }
}

export default StaffCompaniesProvider;
