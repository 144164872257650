import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import 'react-table/react-table.css';
import { preferredValuesPerPage, setPageSelected, valuesPerPage } from 'constants/pagination';
import { AdvisorContext } from 'containers/advisor';
import {
  ADVISORS,
  ADD_ADVISOR,
  NO_ADVISORS,
  SEARCH_ADVISORS,
  ROLE_OPTIONS
} from 'containers/staff-companies/constants';
import SelectWrapper from 'containers/staff-companies/selectWrapper';
import 'containers/staff-companies/styles.scss';
import { Header } from 'containers/staff-companies/staff-companies-list';

const cellEditLink = (companyId, advisorId) => (
  <Link
    to={`advisor/companies/${companyId}/advisor/${advisorId}`}
    style={{ marginRight: '10%', color: 'inherit' }}
  >
    <i className="icon-pencil-line icon-size" style={{ cursor: 'pointer' }} />
  </Link>
);

const AdvisorTable = ({ companyId, advisors, meta }) => {
  const { staffCompaniesProvider } = useContext(AdvisorContext);
  const [ordering, setOrdering] = useState('user__first_name');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(preferredValuesPerPage().value);
  const [role, setRole] = useState('');

  const fetchData = useCallback(
    _.debounce(() => {
      staffCompaniesProvider.listAdvisors(companyId, {
        page,
        search,
        ordering,
        role,
        page_size: pageSize
      });
    }, 500),
    [page, search, ordering, pageSize, role]
  );

  useEffect(() => {
    fetchData();
    return () => fetchData.cancel();
  }, [fetchData]);

  const onPageChange = pageIndex => {
    const pageNumber = pageIndex + 1;
    setPage(pageNumber);
  };

  const onSearchChange = e => {
    onPageChange(0);
    setSearch(e.target.value);
  };

  const onRoleChange = e => {
    onPageChange(0);
    setRole(e);
  };

  const onPageSizeChange = ({ value }) => {
    setPage(1);
    setPageSize(value);
    setPageSelected({ value });
  };

  const onSortedChange = sorted => {
    const sort = sorted[0];
    const userFields = ['first_name', 'last_name', 'email'];
    const sortId = userFields.includes(sort.id) ? `user__${sort.id}` : sort.id;
    setOrdering(`${sort.desc ? '-' : ''}${sortId}`);
  };

  const getPaginationHeader = () => {
    const preferredValue = preferredValuesPerPage();
    const currentOption = valuesPerPage.find(option => option.value === preferredValue.value);
    return (
      <Select
        className="select-pagination"
        isSearchable={false}
        onChange={onPageSizeChange}
        options={valuesPerPage}
        value={currentOption}
        styles={{
          container: styles => ({
            ...styles,
            boxSizing: 'border-box',
            minWidth: '145px',
            height: '38px'
          })
        }}
      />
    );
  };

  const columns = [
    {
      Header: Header('First Name'),
      accessor: 'first_name',
      sortable: true,
      headerClassName: 'hidden'
    },
    {
      Header: Header('Last Name'),
      accessor: 'last_name',
      sortable: true,
      headerClassName: 'hidden'
    },
    {
      Header: Header('Email'),
      accessor: 'email',
      sortable: true,
      headerClassName: 'hidden'
    },
    {
      Header: Header('Phone'),
      accessor: 'phone',
      sortable: true,
      headerClassName: 'hidden'
    },
    {
      Header: Header('Role'),
      Cell: ({ original }) => (
        <span>{ROLE_OPTIONS.find(({ value }) => value === original.role)?.label || ''}</span>
      ),
      sortable: true,
      headerClassName: 'hidden'
    },
    {
      Header: null,
      Cell: ({ original }) => cellEditLink(companyId, original.id),
      maxWidth: 80,
      headerClassName: 'hidden'
    }
  ];

  return (
    <div className="staff-companies">
      <div id="InvestorHeader" className="header" style={{ marginTop: '15px' }}>
        <div className="title-container">
          <h4 style={{ height: '38px' }}>{ADVISORS}</h4>
        </div>
        <div className="right-header">
          <Link to={`/advisor/companies/${companyId}/advisor/create`}>
            <button type="button" className="btn btn-primary" style={{ height: '38px' }}>
              {ADD_ADVISOR}
            </button>
          </Link>
        </div>
      </div>

      <div className="table-container-filters">
        <div className="form-group search client filters">
          <div>
            <input
              className="form-control react-autosuggest__input"
              placeholder={SEARCH_ADVISORS}
              value={search}
              onChange={onSearchChange}
              style={{ height: '38px', width: '250px' }}
            />
            <span className="icon-search_icn" />
          </div>
          <div style={{ display: 'flex' }}>
            <SelectWrapper
              styles={{ width: '160px', marginRight: '2%' }}
              options={[
                {
                  value: '',
                  label: 'All Roles'
                },
                ...ROLE_OPTIONS
              ]}
              value={role}
              onChange={onRoleChange}
            />
            <div className="select-options__pagination">{getPaginationHeader()}</div>
          </div>
        </div>
      </div>

      <ReactTable
        data={advisors}
        columns={columns}
        pageSize={pageSize}
        className="-striped -highlight"
        page={page - 1}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortedChange={onSortedChange}
        minRows={1}
        pages={meta?.total_pages || 0}
        showPageSizeOptions={false}
        noDataText={NO_ADVISORS}
        resizable={false}
        manual
      />
    </div>
  );
};

AdvisorTable.propTypes = {
  companyId: PropTypes.number.isRequired
};

export default connect(state => ({
  advisors: state.staffCompanies.advisorList,
  meta: state.staffCompanies.advisorMeta
}))(AdvisorTable);
