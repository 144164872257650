/* eslint-disable no-restricted-syntax, no-underscore-dangle */
import { usePDF } from '@react-pdf/renderer';
import { ProposalPropTypes } from 'components/advisor/proposal/body/sections/types';
import {
  INVESTOR_PROPOSAL_TYPE,
  MODEL_PROPOSAL_TYPE,
  PROSPECT_PROPOSAL_TYPE,
  SECURITY_PROPOSAL_TYPE
} from 'components/advisor/proposal/constants';
import { PREPARED_BY_COMPANY } from 'components/advisor/templates/sections/cover/constants';
import LoadingButton from 'components/loading-button';
import { AdvisorContext } from 'containers/advisor';
import { PROPOSAL_DEFAULT_TEMPLATE } from 'containers/advisor/templates/defaults';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { generateReport, getInvestorReportCoverData, getReportMetadata } from 'reports/utils';
import ReportViewerModal from 'reports/viewer/modal';
import { trackAmplitudeEvent } from 'utils/tracking';
import { getTargetAccountIds } from 'utils/utils';
import ProposalReport from '..';
import { getFullyLoadedCharts } from './utils';

const sanitizeDraftProposal = text => text.replace(/\(#\d+\)$/, '');

const ProposalReportViewer = ({
  collapsedHoldings,
  collapsedRegions,
  collapsedSectors,
  collapsedStyles,
  collapsedTopHoldings,
  currentReport,
  isInvalidTemplate,
  orientation,
  proposal,
  proposalType,
  reportUrl,
  savedCharts,
  security,
  subtitle,
  taxonomies
}) => {
  if (!proposal) return null;

  const [report, setReport] = usePDF();

  const [attachments, setAttachments] = useState({});
  const [coverData, setCoverData] = useState({});
  const [downloadedExternalDocuments, setDownloadedExternalDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [title, setTitle] = useState(undefined);
  const [totalPages, setTotalPages] = useState(0);
  const [url, setUrl] = useState(null);

  const {
    investorProvider,
    modelProvider,
    proposalProvider,
    prospectProvider,
    user,
    userProvider
  } = useContext(AdvisorContext);

  const fullyLoadedCharts = getFullyLoadedCharts(proposal, savedCharts);
  const hasAttachments = !_.isEmpty(attachments);
  const fullyLoadedAttachments =
    !hasAttachments || Object.keys(attachments).length === downloadedExternalDocuments.length;

  const partialLoadedReportAssets = fullyLoadedCharts && !loading;
  const fullyLoadedReportAssets = fullyLoadedCharts && fullyLoadedAttachments && !loading;

  const templateContent =
    proposal.template_content || proposal.template.content || PROPOSAL_DEFAULT_TEMPLATE;
  const coverSection = templateContent._cover;
  const proposalAccounts = getTargetAccountIds(proposal);
  const coverParams = {
    accounts: proposalAccounts,
    prepared_by: coverSection.preparedBy,
    prepared_for: coverSection.preparedFor
  };
  const proposalSubtitle = sanitizeDraftProposal(
    coverData.prepared_for || subtitle || proposal?.target?.name || ''
  );
  const withCustomBranding = userProvider.isBusinessOrAbove(user);

  const changeOrientation = value => {
    proposalProvider.changeCurrentReportOrientation(value);
  };

  const handleSendToAddepar = () => {
    setSending(true);
    const { investor } = proposal.target;
    proposalProvider
      .sendToAddepar(currentReport.id, investor, investor.is_prospect)
      .then(({ error }) => {
        if (error) throw Error();
        else toast.success('The PDF report was sent successfully');
      })
      .catch(() => {
        toast.error('Something went wrong while sending the PDF report');
      })
      .finally(() => {
        setSending(false);
      });
  };

  useEffect(() => {
    const coverReportSecurity = {
      prepared_by: `${user.first_name} ${user.last_name}`,
      prepared_for: security.ticker_name,
      company_name: user.advisor.company.name,
      logo: user.advisor.company.logo,
      email: user.email
    };
    const getReportCoverData = async () => {
      if (proposalType === MODEL_PROPOSAL_TYPE)
        return modelProvider.getReportCoverData(proposal.target.id, coverParams);
      if (proposalType === SECURITY_PROPOSAL_TYPE) return coverReportSecurity; // To do: api call get report cover data security

      return getInvestorReportCoverData(
        proposal.target.investor,
        investorProvider,
        prospectProvider,
        coverParams
      );
    };

    setLoading(true);
    const isCustom = coverParams.prepared_for === 'custom';
    getReportCoverData()
      .then(data => {
        setCoverData({
          ...data,
          prepared_for: isCustom
            ? coverSection.preparedForCustomName
            : sanitizeDraftProposal(data.prepared_for),
          prepared_by_company: coverSection.preparedBy === PREPARED_BY_COMPANY
        });
        setTitle(coverSection.title);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [JSON.stringify(coverSection), JSON.stringify(proposalAccounts)]);

  // It's necessary to use the `usePDF` hook because the base report needs to be
  // rendered in order to obtain the number of total pages, as well as the pages where
  // external documents need to be inserted.
  useEffect(() => {
    setAttachments({});
    if (partialLoadedReportAssets) {
      setReport(undefined);
      setReport(
        <ProposalReport
          collapsedHoldings={collapsedHoldings}
          collapsedRegions={collapsedRegions}
          collapsedSectors={collapsedSectors}
          collapsedStyles={collapsedStyles}
          collapsedTopHoldings={collapsedTopHoldings}
          coverData={coverData}
          orientation={orientation}
          proposal={{ ...proposal, ...savedCharts }}
          proposalType={proposalType}
          setAttachments={setAttachments}
          setTotalPages={setTotalPages}
          subtitle={proposalSubtitle}
          taxonomies={taxonomies}
          title={title}
          totalPages={totalPages}
          user={user}
          withCustomBranding={withCustomBranding}
        />
      );
    }
  }, [
    JSON.stringify(collapsedHoldings),
    JSON.stringify(collapsedRegions),
    JSON.stringify(collapsedSectors),
    JSON.stringify(collapsedStyles),
    JSON.stringify(collapsedTopHoldings),
    JSON.stringify(coverData),
    JSON.stringify(proposal),
    JSON.stringify(savedCharts),
    loading,
    orientation,
    title
  ]);

  useEffect(() => {
    if (fullyLoadedCharts && !!totalPages && report.url)
      generateReport(
        report.url,
        totalPages,
        attachments,
        downloadedExternalDocuments,
        setDownloadedExternalDocuments,
        getReportMetadata('Investment Proposal', proposalSubtitle)
      ).then(url => {
        if (!url) return;
        if (url !== reportUrl) proposalProvider.saveLatestPdfReportUrl(url);
        setUrl(url);
      });
  }, [fullyLoadedCharts, totalPages, report.url]);

  return (
    <>
      <ReportViewerModal
        changeOrientation={changeOrientation}
        fullyLoadedReportAssets={fullyLoadedReportAssets}
        isInvalidTemplate={isInvalidTemplate}
        orientation={orientation}
        url={url}
        onLoad={() => {
          if (proposalType === PROSPECT_PROPOSAL_TYPE || proposalType === INVESTOR_PROPOSAL_TYPE) {
            const amplitudePost = {
              id: proposal.id,
              investor: proposal.target.investor.id,
              is_prospect: proposal.target.investor.is_prospect,
              with_recommended: !!proposal.recommended,
              with_benchmark: !!proposal.benchmark
            };
            trackAmplitudeEvent('proposal.downloaded', amplitudePost);
          }
          if (proposalType === MODEL_PROPOSAL_TYPE) {
            const amplitudePost = {
              id: proposal.id,
              model: proposal.target.name,
              with_recommended: !!proposal.recommended,
              with_benchmark: !!proposal.benchmark
            };
            trackAmplitudeEvent('model_analysis.downloaded', amplitudePost);
          }
        }}
      />

      {currentReport?.pdf_report && proposal.can_send_to_addepar && (
        <LoadingButton
          type="button"
          onClick={handleSendToAddepar}
          className="btn btn-secondary"
          loading={sending}
        >
          Send to Addepar
        </LoadingButton>
      )}
    </>
  );
};

ProposalReportViewer.propTypes = {
  collapsedHoldings: PropTypes.array.isRequired,
  collapsedRegions: PropTypes.array.isRequired,
  collapsedSectors: PropTypes.array.isRequired,
  collapsedStyles: PropTypes.array.isRequired,
  collapsedTopHoldings: PropTypes.array.isRequired,
  currentReport: PropTypes.object,
  isInvalidTemplate: PropTypes.bool,
  orientation: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes),
  proposalType: PropTypes.string,
  reportUrl: PropTypes.string,
  savedCharts: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
  taxonomies: PropTypes.array.isRequired
};

ProposalReportViewer.defaultProps = {
  currentReport: null,
  isInvalidTemplate: false,
  proposal: null,
  proposalType: INVESTOR_PROPOSAL_TYPE,
  reportUrl: null
};

export default connect(state => ({
  collapsedHoldings: state.proposals.collapsedHoldings,
  collapsedRegions: state.proposals.collapsedRegions,
  collapsedSectors: state.proposals.collapsedSectors,
  collapsedStyles: state.proposals.collapsedStyles,
  collapsedTopHoldings: state.proposals.collapsedTopHoldings,
  currentReport: state.proposals.currentReport,
  orientation: state.proposals.currentReportSettings.orientation,
  proposal: state.proposals.view,
  reportUrl: state.proposals.url,
  savedCharts: state.proposals.charts,
  security: state.customSecurity.currentSecurity,
  taxonomies: state.market.taxonomies
}))(React.memo(ProposalReportViewer));
