import CoverSection from 'components/advisor/templates/sections/cover';
import PropTypes from 'prop-types';
import React from 'react';

const IPSCoverSection = props => (
  <CoverSection
    {...props}
    preparedForCustomName={props.ips?.proposal?.template_content?._cover?.preparedForCustomName}
  />
);

IPSCoverSection.propTypes = {
  propertyKey: PropTypes.string.isRequired
};

export default IPSCoverSection;
