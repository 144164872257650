export const SERVER_ERROR = 'error-500';
export const SKIP_ERROR = 'skip-error';
export const CLEAR_SERVER_ERRORS = 'clear-server-errors';
export const FETCHING = 'FETCHING';
export const FETCHING_FAILURE = 'FETCHING_FAILURE';

export const DROP_NO_PERFORMANCE = 'drop-no-performance';
export const SET_NO_PERFORMANCE = 'set-no-performance';
export const WAKEUP_ACCOUNT = 'wakeup-account';

export const ACCOUNT_CLEAR = 'account-clear';
export const ACCOUNT_CLEAR_ES = 'account-clear-es';
export const ACCOUNT_COUNT_EXCLUDED = 'account-count-excluded';
export const ACCOUNT_CREATE = 'account-create';
export const ACCOUNT_DELETE = 'account-delete';
export const ACCOUNT_EDIT = 'account-edit';
export const ACCOUNT_GET = 'get-account';
export const ACCOUNT_GET_DRIFT_LEVELS_DISTRIBUTION = 'account-get-drift-levels-distribution';
export const ACCOUNT_LIST = 'get-account-list';
export const ACCOUNT_LIST_ES = 'get-account-list-es';
export const ACCOUNT_NOTE_LIST = 'account-note-list';
export const ACCOUNT_PRISM_SCORE_COMPLETED = 'account-prism-score-completed';
export const ACCOUNT_PRISM_SCORE_FAILED = 'account-prism-score-failed';
export const ACCOUNT_PRISM_SCORE_IN_PROGRESS = 'account-prism-score-in-progress';
export const ACCOUNT_SAVE_CHART_IMAGE = 'account-save-chart-image';
export const ACCOUNT_SET_BREAKDOWN_CUSTOM_SECURITIES = 'account-set-breakdown-custom-securities';
export const ACCOUNT_UPDATE = 'account-update';
export const ACCOUNT_UPDATE_SELECTED_IDS = 'account-update-selected-ids';

export const COMPANY_LIMITS_GET = 'company-limits-get';
export const COMPANY_LIST_COLLEAGUES = 'company-colleagues-list';
export const COMPANY_STATS_GET = 'company-stats-get';
export const COMPANY_TEMPLATES_GET = 'company-templates-get';
export const COMPANY_TEMPLATES_UPDATE = 'company-templates-update';

export const CLEAR_QUESTIONS = 'clear-questions';
export const GET_QUESTIONNAIRE_TEMPLATE = 'get-questionnaire-template';
export const GET_QUESTIONNAIRE_TEMPLATE_QUESTIONS = 'get-questionnaire-template-questions';
export const GET_QUESTIONS = 'get-questions';
export const LIST_QUESTIONNAIRE_TEMPLATES = 'list-questionnaire-templates';
export const SAVE_ANSWER = 'save-answer';
export const SAVE_DOCUMENTATION_NOTE = 'save-documentation-note';

export const GET_ACCOUNT_PRISM_DISTRIBUTION = 'get-account-prism-distribution';
export const GET_ACCOUNT_TARGET_RATING = 'get-account-target-rating';
export const GET_ACCOUNTS_BY_RISK_LEVEL = 'get-accounts-by-risk-level';

export const CONNECT_INVITATION_SENT = 'connect-invitation-sent';

export const GET_COMPOSITE_RETURNS = 'get-composite-returns';
export const GET_ENHANCEMENT_RETURNS = 'get-enhancement-returns';

export const INVESTOR_COUNT_NEW = 'investor-count-new';
export const INVESTOR_CREATE = 'investor-create';
export const INVESTOR_DELETE = 'investor-delete';
export const INVESTOR_GET = 'investor-get';
export const INVESTOR_GET_ACCOUNTS = 'investor-get-accounts';
export const INVESTOR_GET_ACCOUNTS_WITH_HOUSEHOLD = 'investor-get-accounts-with-household';
export const INVESTOR_GET_DRIFT_LEVELS_DISTRIBUTION = 'investor-get-drift-levels-distribution';
export const INVESTOR_GET_EXCEPTIONS = 'investor-get-exceptions';
export const INVESTOR_LIST = 'investor-list';
export const INVESTOR_LIST_ES = 'get-investor-list-es';
export const INVESTOR_NOTE_LIST = 'investor-note-list';
export const INVESTOR_SAVE_CHART_IMAGE = 'investor-save-chart-image';
export const INVESTOR_SET_BREAKDOWN_CUSTOM_SECURITIES = 'investor-set-breakdown-custom-securities';
export const INVESTOR_UPDATE = 'investor-update';
export const INVESTOR_UPDATE_SELECTED_IDS = 'investor-update-selected-ids';

export const INVESTOR_GOAL_CREATE = 'investor-goal-create';
export const INVESTOR_GOAL_LIST = 'investor-goal-list';
export const INVESTOR_GOAL_GET = 'investor-goal-get';
export const INVESTOR_GOAL_EDIT = 'investor-goal-edit';
export const INVESTOR_GOAL_DELETE = 'investor-goal-delete';

export const GET_INVESTOR_PRISM_DISTRIBUTION = 'get-investor-prism-distribution';

export const CLEAR_RISK_ANALYSIS = 'clear-risk-analysis';
export const MODEL_ANALYZE_RISK = 'model-analyze-risk';
export const MODEL_CLEAR = 'model-clear';
export const MODEL_COMMON_BENCHMARKS = 'model-common-benchmarks';
export const MODEL_CREATE = 'model-create';
export const MODEL_DELETE = 'model-delete';
export const MODEL_EDIT = 'model-edit';
export const MODEL_GET = 'model-get';
export const MODEL_GET_BY_ID = 'model-get-by-id';
export const MODEL_GET_STRATEGY_STATS = 'model-get-strategy-stats';
export const MODEL_LIST = 'model-list';
export const MODEL_LIST_ALL = 'model-list-all';
export const MODEL_LIST_ES = 'model-list-es';
export const MODEL_MARKETPLACE_GET = 'model-marketplace-get';
export const MODEL_MARKETPLACE_LIST = 'model-marketplace-list';
export const MODEL_MARKETPLACE_PROVIDERS = 'model-marketplace-provider';
export const MODEL_MARKETPLACE_REQUEST_SUBSCRIPTION = 'model-marketplace-request-subscription';
export const MODEL_PRISM_SCORE_COMPLETED = 'model-prism-score-completed';
export const MODEL_PRISM_SCORE_FAILED = 'model-prism-score-failed';
export const MODEL_PRISM_SCORE_IN_PROGRESS = 'model-prism-score-in-progress';
export const MODEL_SAVE_CHART_IMAGE = 'model-save-chart-image';
export const MODEL_SET_BREAKDOWN_CUSTOM_SECURITIES = 'model-set-breakdown-custom-securities';
export const MODEL_UPDATE = 'model-update';
export const MODEL_UPDATE_SELECTED_IDS = 'model-update-selected-ids';
export const MODEL_VIEW = 'model-view';

export const ADVISOR_CREATE = 'advisor-create';
export const ADVISOR_DELETE = 'advisor-delete';
export const ADVISOR_EDIT = 'advisor-edit';
export const ADVISOR_GET = 'advisor-get';
export const ADVISOR_LIST = 'advisor-list';
export const ADVISOR_LIST_ES = 'advisor-list-es';
export const ADVISOR_STATS_GET = 'advisor-stats-get';
export const ADVISOR_LIST_FOLLOWERS = 'advisor-list-followers';
export const ADVISOR_UPDATE = 'advisor-update';
export const ADVISOR_VIEW = 'advisor-view';

export const EXCEPTIONS_LIST = 'exceptions-list';
export const EXCEPTIONS_LIST_ES = 'exceptions-list-es';
export const EXCEPTIONS_TAGS = 'exceptions-tags';
export const ADVISOR_DOWNLOADING_DATA = 'advisor-downloading-data';
export const ADVISOR_DOWNLOADING_DATA_FINISHED = 'advisor-downloading-data-finished';

export const GET_PRISM_TARGET_HISTORY = 'get-prism_target-history';

export const CLASSIFICATIONS_GET = 'classifications-get';
export const CLASSIFICATIONS_TAXONOMY_GET = 'classifications-taxonomy-get';
export const SECURITY_SEARCH = 'security-search';
export const SECURITY_GET = 'security-get';
export const SECURITY_LIST_ES = 'security-list-es';
export const SECURITY_LOADED_CSV_POSITIONS = 'security-loaded-csv-positions';
export const SECURITY_BENCHMARKS_LIST = 'security-benchmark-list';

export const PRICE_LIST = 'price-list';
export const PRICE_GET = 'price-get';
export const PRICE_SET = 'price-set';
export const PRICE_CLEAR = 'price-clear';

export const SUBSCRIPTION_GET = 'subscription-get';
export const INVOICE_UPCOMING = 'upcoming-invoice';

export const PROMO_CODE_GET = 'promo-code-get';
export const PROMO_CODE_CLEAR = 'promo-code-clear';

export const CARD_DELETE = 'card-delete';
export const CARD_LIST = 'card-list';
export const CARD_SET_AS_DEFAULT = 'card-set-as-default';
export const CARD_SETUP_INTENT = 'card-setup-payment-intent';

export const CHARGE_LIST = 'charge-list';

export const PORTFOLIO_VIEW = 'portfolio-view';
export const PORTFOLIO_EDIT = 'portfolio-edit';
export const PORTFOLIO_LIST = 'portfolio-list';
export const PORTFOLIO_GET = 'portfolio-get';
export const PORTFOLIO_CREATE = 'portfolio-create';
export const PORTFOLIO_UPDATE = 'portfolio-update';
export const PORTFOLIO_DELETE = 'portfolio-delete';

export const PROPOSAL_CLEAR = 'proposal-clear';
export const PROPOSAL_CREATE = 'proposal-create';
export const PROPOSAL_GET = 'proposal-get';
export const PROPOSAL_MODEL_UPDATE = 'proposal-model-update';
export const PROPOSAL_SAVE_CHART_IMAGE = 'proposal-save-chart-image';
export const PROPOSAL_SET_COLLAPSED_HOLDINGS = 'proposal-set-collapsed-holdings';
export const PROPOSAL_SET_COLLAPSED_REGIONS = 'proposal-set-collapsed-regions';
export const PROPOSAL_SET_COLLAPSED_SECTORS = 'proposal-set-collapsed-sectors';
export const PROPOSAL_SET_COLLAPSED_STYLES = 'proposal-set-collapsed-styles';
export const PROPOSAL_SET_COLLAPSED_TOP_HOLDINGS = 'proposal-set-collapsed-top-holdings';
export const PROPOSAL_UPDATE = 'proposal-update';

export const PROPOSAL_REPORT_CLEAR = 'proposal-report-clear';
export const PROPOSAL_REPORT_UNSAVED = 'proposal-report-unsaved';
export const PROPOSAL_REPORTS_CREATE = 'proposal-reports-create';
export const PROPOSAL_REPORTS_CREATE_REVIEW = 'proposal-reports-create-review';
export const PROPOSAL_REPORTS_GET = 'proposal-reports-get';
export const PROPOSAL_REPORTS_LIST = 'proposal-reports-list';
export const PROPOSAL_REPORTS_UPDATE = 'proposal-reports-update';
export const PROPOSAL_SAVE_REPORT_PDF_URL = 'proposal-save-report-pdf-url';

export const IPS_CLEAR = 'ips-clear';
export const IPS_CREATE = 'ips-create';
export const IPS_GENERATE = 'ips-generate';
export const IPS_GET = 'ips-get';
export const IPS_LIST = 'ips-list';
export const IPS_SAVE_CHART_IMAGE = 'ips-save-chart-image';
export const IPS_SET_COLLAPSED_HOLDINGS = 'ips-set-collapsed-holdings';
export const IPS_SET_COLLAPSED_REGIONS = 'ips-set-collapsed-regions';
export const IPS_SET_COLLAPSED_SECTORS = 'ips-set-collapsed-sectors';
export const IPS_SET_COLLAPSED_STYLES = 'ips-set-collapsed-styles';
export const IPS_SET_COLLAPSED_TOP_HOLDINGS = 'ips-set-collapsed-top-holdings';
export const IPS_UPDATE = 'ips-update';
export const IPS_VIEW = 'ips-view';

export const IPS_REPORT_CLEAR = 'ips-report-clear';
export const IPS_REPORT_UNSAVED = 'ips-report-unsaved';
export const IPS_REPORTS_CREATE = 'ips-reports-create';
export const IPS_REPORTS_CREATE_REVIEW = 'ips-reports-create-review';
export const IPS_REPORTS_GET = 'ips-reports-get';
export const IPS_REPORTS_LIST = 'ips-reports-list';
export const IPS_REPORTS_UPDATE = 'ips-reports-update';
export const IPS_SAVE_REPORT_PDF_URL = 'ips-save-report-pdf-url';

export const TRANSACTION_LIST = 'transaction-list';
export const TRANSACTION_GET = 'transaction-get';
export const TRANSACTION_CREATE = 'transaction-create';
export const TRANSACTION_UPDATE = 'transaction-update';
export const TRANSACTION_DELETE = 'transaction-delete';
export const TRANSACTION_EDIT = 'transaction-edit';

export const HOUSEHOLD_GET = 'household-get';
export const HOUSEHOLD_GET_DRIFT_LEVELS_DISTRIBUTION = 'household-get-drift-levels-distribution';
export const HOUSEHOLD_GET_MEMBER_SUGGESTIONS = 'household-get-member-suggestions';
export const HOUSEHOLD_GET_PRISM_DISTRIBUTION = 'household-get-prism-distribution';
export const HOUSEHOLD_LINK = 'household-link';
export const HOUSEHOLD_LIST = 'household-list';
export const HOUSEHOLD_LIST_ES = 'household-list-es';
export const HOUSEHOLD_UNLINK = 'household-unlink';
export const HOUSEHOLD_UPDATE = 'household-update';
export const HOUSEHOLD_UPDATE_SELECTED_IDS = 'household-update-selected-ids';

export const GET_PRISM_SCORE = 'get-prism-score';
export const GET_PROSPECT_ACCOUNTS = 'accounts-for-prospect';
export const GET_PROSPECT_ACCOUNTS_WITH_HOUSEHOLD = 'accounts-for-prospect-with-household';
export const PROSPECT_GET = 'prospect-get';
export const PROSPECT_CONVERT = 'prospect-convert';
export const PROSPECT_CREATE = 'prospect-create';
export const PROSPECT_DELETE = 'prospect-delete';
export const PROSPECT_GET_DRIFT_LEVELS_DISTRIBUTION = 'prospect-get-drift-levels-distribution';
export const PROSPECT_LIST_ES = 'prospect-list-es';
export const PROSPECT_NOTE_LIST = 'prospect-note-list';
export const PROSPECT_SET_BREAKDOWN_CUSTOM_SECURITIES = 'prospect-set-breakdown-custom-securities';
export const PROSPECT_UPDATE_SELECTED_IDS = 'prospect-update-selected-ids';
export const PROSPECTS_LIST = 'prospect-list';

export const GET_PROSPECT_PRISM_DISTRIBUTION = 'prospect-prism-distribution';

export const CHANGE_REPORT_ORIENTATION = 'change-report-orientation';

export const GET_ACCOUNT_PRISM_INTENTS = 'get-account-prism-intents';
export const GET_MODEL_PORTFOLIO_PRISM_INTENTS = 'get-model-portfolio-prism-intents';

export const STAFF_COMPANY_CREATE = 'staff-company-create';
export const STAFF_COMPANY_GET = 'staff-company-get';
export const STAFF_COMPANY_LIST = 'staff-company-list';
export const STAFF_COMPANY_EDIT = 'staff-company-edit';
export const STAFF_COMPANY_ADVISOR_LIST = 'staff-company-advisor-list';
export const STAFF_COMPANY_ADVISOR_CREATE = 'staff-company-advisor-create';
export const STAFF_COMPANY_ADVISOR_GET = 'staff-company-advisor-get';
export const STAFF_COMPANY_ADVISOR_EDIT = 'staff-company-advisor-edit';

export const TEAM_LIST = 'team-list';
export const TEAM_CREATE = 'team-create';
export const TEAM_EDIT = 'team-edit';
export const TEAM_DELETE = 'team-delete';
export const TEAM_SELECT = 'team-select';

export const CUSTOM_SECURITY_LIST = 'custom-security-list';
export const CUSTOM_SECURITY_CREATE = 'custom-security-create';
export const CUSTOM_SECURITY_CLEAR = 'custom-security-clear';
export const CUSTOM_SECURITY_SAVE_CURRENT = 'custom-security-save-current';
export const CUSTOM_SECURITY_RETURNS_CREATE = 'custom-security-returns-create';
export const CUSTOM_SECURITY_GET = 'custom-security-get';
export const CUSTOM_SECURITY_EDIT = 'custom-security-edit';
export const CUSTOM_SECURITY_RETURNS_BULK_SAVE = 'custom-security-returns-save-bulk';
export const CUSTOM_SECURITY_RETURNS_BULK_REMOVE = 'custom-security-returns-bulk-remove';
export const CUSTOM_SECURITY_RETURNS_BULK_NAME_SUGGESTIONS =
  'custom-security-returns-bulk-suggestions';
export const CUSTOM_SECURITY_RETURNS_GET = 'custom-security-returns-get';
export const CUSTOM_SECURITY_RETURNS_CLEAR = 'custom-security-clear-returns';
export const CUSTOM_SECURITY_SEARCH = 'custom-security-search';
export const CUSTOM_SECURITY_UNDERLYING_HOLDINGS_GET = 'custom-security-underlying-holdings-get';
export const CUSTOM_SECURITY_UNDERLYING_HOLDINGS_CLEAR =
  'custom-security-underlying-holdings-clear';

export const AI_ASSISTANT_SCAN_VERB_SAVE = 'ai-assistant-scan-verb-save';
export const AI_ASSISTANT_SCAN_FUNCTION_UPDATE = 'ai-assistant-scan-update-save';
export const AI_ASSISTANT_SCAN_CLEAR_SCAN = 'ai-assistant-scan-clear';
export const AI_ASSISTANT_SCAN_HISTORY_LIST = 'ai-assistant-scan-history-list';

export const SURVEILLANCE_EXCEPTIONS_LIST_ES = 'surveillance-exceptions-list-es';
