import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import moment from 'moment';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './styles.scss';

const FIVE_YEARS_AGO_TO_TODAY = 5;
const THREE_YEARS_AGO_TO_TODAY = 3;
const ONE_YEAR_AGO_TO_TODAY = 1;

const DATE_FORMAT_MMM_YYYY_UNDERCUP = 'MMM yyyy';
const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
const DATE_FORMAT_MMM_YYYY = 'MMM YYYY';

const ReactDateRangePickerWithSelectors = ({ value, onChange, className }) => {
  const [dates, setDates] = useState([
    value?.[0]
      ? moment(value[0], DATE_FORMAT_YYYY_MM_DD).toDate()
      : moment().subtract(5, 'years').toDate(),
    value?.[1] ? moment(value[1], DATE_FORMAT_YYYY_MM_DD).toDate() : moment().toDate()
  ]);

  const [showModal, setShowModal] = useState(false);
  const [activeButton, setActiveButton] = useState(FIVE_YEARS_AGO_TO_TODAY);
  const modalRef = useRef(null);

  useEffect(() => {
    if (!value)
      onChange([
        moment().subtract(5, 'years').format(DATE_FORMAT_YYYY_MM_DD),
        moment().format(DATE_FORMAT_YYYY_MM_DD)
      ]);
  }, [value, onChange]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) setShowModal(false);
    };

    if (showModal) document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  const handleShortcut = years => {
    setActiveButton(years);
    const startDate = moment().subtract(years, 'years').format(DATE_FORMAT_YYYY_MM_DD);
    const endDate = moment().format(DATE_FORMAT_YYYY_MM_DD);
    setDates([
      moment(startDate, DATE_FORMAT_YYYY_MM_DD).toDate(),
      moment(endDate, DATE_FORMAT_YYYY_MM_DD).toDate()
    ]);
    onChange([startDate, endDate]);
    setShowModal(false);
  };

  const handleDateChange = (date, type) => {
    if (!date) return;
    const formattedDate = moment(date).format(DATE_FORMAT_YYYY_MM_DD);

    if (type === 'start')
      if (moment(date).isAfter(moment(dates[1]))) {
        setDates([date, date]);
        onChange([formattedDate, formattedDate]);
      } else {
        setDates([date, dates[1]]);
        onChange([formattedDate, moment(dates[1]).format(DATE_FORMAT_YYYY_MM_DD)]);
      }
    else if (moment(date).isBefore(moment(dates[0]))) {
      setDates([date, date]);
      onChange([moment(dates[0]).format(DATE_FORMAT_YYYY_MM_DD), formattedDate]);
    } else {
      setDates([dates[0], date]);
      onChange([moment(dates[0]).format(DATE_FORMAT_YYYY_MM_DD), formattedDate]);
    }
  };

  const handleCustomClick = () => {
    setActiveButton('custom');
    setShowModal(prev => !prev);
  };

  const formattedDateDisplay = `${moment(dates[0]).format(DATE_FORMAT_MMM_YYYY)} - ${moment(
    dates[1]
  ).format(DATE_FORMAT_MMM_YYYY)}`;

  const renderYearsButton = years => (
    <button
      className={cn('btn btn-date-picker', { active: activeButton === years })}
      onClick={() => handleShortcut(years)}
      type="button"
    >
      {years}Y
    </button>
  );

  return (
    <div className={`date-picker-container-with-selectors ${className}`}>
      <div className="date-picker-main-container">
        <div className="date-picker-buttons">
          {renderYearsButton(ONE_YEAR_AGO_TO_TODAY)}
          {renderYearsButton(THREE_YEARS_AGO_TO_TODAY)}
          {renderYearsButton(FIVE_YEARS_AGO_TO_TODAY)}
        </div>

        <button
          type="button"
          onClick={handleCustomClick}
          className={cn('btn btn-date-picker btn-calendar', { active: activeButton === 'custom' })}
        >
          Custom <i className="icon-calendar" />
        </button>
      </div>

      {showModal && (
        <div className="date-picker-modal" ref={modalRef}>
          <div className="date-picker-wrapper">
            <div className="calendar-wrapper">
              <div className="calendar-label">Start Date</div>
              <DatePicker
                value={dates[0]}
                onChange={date => handleDateChange(date, 'start')}
                maxDate={moment().subtract(1, 'days').toDate()}
                minDate={moment('1990-01-01', DATE_FORMAT_YYYY_MM_DD).toDate()}
                maxDetail="year"
                className="react-daterange-picker__calendar-start-date"
                format={DATE_FORMAT_MMM_YYYY_UNDERCUP}
                clearIcon={null}
                defaultActiveStartDate={dates[0]}
              />
            </div>

            <div className="calendar-wrapper">
              <div className="calendar-label">End Date</div>
              <DatePicker
                value={dates[1]}
                onChange={date => handleDateChange(date, 'end')}
                maxDate={moment().subtract(1, 'days').toDate()}
                minDate={moment('1990-01-01', DATE_FORMAT_YYYY_MM_DD).toDate()}
                maxDetail="year"
                className="react-daterange-picker__calendar-end-date"
                format={DATE_FORMAT_MMM_YYYY_UNDERCUP}
                clearIcon={null}
                defaultActiveStartDate={dates[1]}
              />
            </div>
          </div>
        </div>
      )}

      {formattedDateDisplay && <div className="selected-date-range">{formattedDateDisplay}</div>}
    </div>
  );
};

ReactDateRangePickerWithSelectors.defaultProps = {
  className: ''
};

ReactDateRangePickerWithSelectors.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired
};

export default ReactDateRangePickerWithSelectors;
