import cn from 'classnames';
import { EDIT_RESTRICTED_SECTION } from 'components/advisor/utils/constants';
import { FormGroup, VerboseErrorInput } from 'components/form';
import Choice from 'components/form/choice';
import AdministrativePermissionsTooltip from 'components/utils/administrative-permissions-tooltip';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { validation } from 'utils/form';
import { PREPARED_BY_OPTIONS, PREPARED_FOR_OPTIONS } from './constants';
import PreparedForCustomInput from './prepared-for-custom-name';
import './styles.scss';

const CoverSection = ({
  actionsDisabled,
  allowEdit,
  body,
  editing,
  preparedBy,
  preparedFor,
  preparedForCustomName,
  propertyKey,
  saveChanges,
  showPreparedFor,
  title,
  toggleEditing
}) => {
  const { authProvider, location, user } = useContext(AdvisorContext);

  const [submitting, setSubmitting] = useState(false);

  const customInputRef = useRef(null);

  const hasCompliancePermissionsOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);
  const initialValues = {
    preparedBy,
    preparedFor,
    title,
    preparedForCustomName
  };
  const isTemplateView = location.pathname.match('advisor/templates/');
  const isTemplateViewWithCompliancePermissionsOrAbove =
    isTemplateView && hasCompliancePermissionsOrAbove;

  const onPermissionsChange = values => () =>
    saveChanges(propertyKey, { body, title, preparedBy, preparedFor, ...values });

  const onCancel = form => () => {
    form.reset();
    toggleEditing();
  };

  const onSubmit = values => () => {
    setSubmitting(true);
    return saveChanges(propertyKey, { body, ...values })
      .then(() => {
        toggleEditing();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (preparedFor === 'custom' && customInputRef.current) customInputRef.current.focus();
  }, [preparedFor]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={values => values}
      render={({ values, form, invalid }) => (
        <section
          id="cover-section"
          className={cn('cover-section', { 'cover-section--templates': isTemplateView })}
        >
          <div className={cn('body', { editing })}>
            <div className="body__actions">
              {!hasCompliancePermissionsOrAbove && !allowEdit && (
                <span className="hidden-msg">{EDIT_RESTRICTED_SECTION}</span>
              )}

              {!editing && (
                <AdministrativePermissionsTooltip
                  renderTooltip={
                    (!hasCompliancePermissionsOrAbove && !allowEdit) || actionsDisabled
                  }
                  tooltipId="cover-section-edit"
                >
                  <button
                    className="btn btn-secondary"
                    disabled={
                      (!hasCompliancePermissionsOrAbove && !allowEdit) ||
                      actionsDisabled ||
                      submitting
                    }
                    onClick={toggleEditing}
                    type="button"
                  >
                    Edit
                  </button>
                </AdministrativePermissionsTooltip>
              )}

              {editing && (
                <>
                  <button
                    className="btn btn-link text-dark"
                    disabled={submitting}
                    onClick={onCancel(form)}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-secondary"
                    disabled={submitting || invalid}
                    onClick={onSubmit(values)}
                    type="button"
                  >
                    {submitting ? 'Saving...' : 'Save'}
                  </button>
                </>
              )}
            </div>

            <div className="body__content">
              <h2>Report Cover</h2>
              <div className="content__form">
                <div className="form__title">
                  <span>Title</span>
                  <Field
                    name="title"
                    render={({ input, meta }) => (
                      <FormGroup {...input} {...meta}>
                        <VerboseErrorInput
                          className="form-control"
                          disabled={!editing}
                          placeholder="Add report title"
                          type="text"
                          {...input}
                          {...meta}
                        />
                      </FormGroup>
                    )}
                    validate={validation.required}
                  />
                </div>

                {showPreparedFor && (
                  <div className="form__prepared-for">
                    <span>Prepared for</span>
                    {PREPARED_FOR_OPTIONS.map(option => (
                      <label htmlFor={option.value} key={option.value}>
                        <Field
                          component="input"
                          disabled={!editing}
                          name="preparedFor"
                          type="radio"
                          value={option.value}
                        />
                        {option.label}
                      </label>
                    ))}
                    <PreparedForCustomInput
                      editing={editing}
                      preparedFor={values.preparedFor}
                      inputRef={customInputRef}
                    />
                  </div>
                )}

                <div className="form__prepared-by">
                  <span>Prepared by</span>
                  {PREPARED_BY_OPTIONS.map(option => (
                    <label htmlFor={option.value} key={option.value}>
                      <Field
                        component="input"
                        disabled={!editing}
                        name="preparedBy"
                        type="radio"
                        value={option.value}
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {isTemplateViewWithCompliancePermissionsOrAbove && (
            <div className="permissions">
              <span className="permissions__title">Allow advisors to:</span>
              <ul>
                <li>
                  <Choice
                    checked={allowEdit}
                    disabled={editing}
                    title="Change content"
                    toggle={onPermissionsChange({ allowEdit: !allowEdit })}
                  />
                </li>
              </ul>
            </div>
          )}
        </section>
      )}
    />
  );
};

CoverSection.propTypes = {
  actionsDisabled: PropTypes.bool,
  allowEdit: PropTypes.bool,
  body: PropTypes.string,
  editing: PropTypes.bool,
  preparedBy: PropTypes.string,
  preparedFor: PropTypes.string,
  propertyKey: PropTypes.string.isRequired,
  saveChanges: PropTypes.func,
  preparedForCustomName: PropTypes.string,
  showPreparedFor: PropTypes.bool,
  title: PropTypes.string.isRequired,
  toggleEditing: PropTypes.func.isRequired
};

CoverSection.defaultProps = {
  actionsDisabled: false,
  allowEdit: true,
  body: '',
  preparedForCustomName: '',
  editing: false,
  preparedBy: '',
  preparedFor: '',
  saveChanges: () => {},
  showPreparedFor: true
};

export default CoverSection;
