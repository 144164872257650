import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FormGroup, VerboseErrorInput } from 'components/form';
import { PREPARED_FOR_CUSTOM } from '../constants';

const PreparedForCustomInput = ({ editing, preparedFor, inputRef }) => (
  <Field
    name="preparedForCustomName"
    render={({ input, meta }) => (
      <FormGroup {...input} {...meta}>
        <VerboseErrorInput
          className="form-control"
          disabled={!editing || preparedFor !== PREPARED_FOR_CUSTOM}
          placeholder="Prepared for"
          ref={inputRef}
          type="text"
          {...input}
        />
      </FormGroup>
    )}
  />
);

PreparedForCustomInput.propTypes = {
  editing: PropTypes.bool,
  preparedFor: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

PreparedForCustomInput.defaultProps = {
  editing: false,
  preparedFor: '',
  inputRef: null
};

export default PreparedForCustomInput;
