import {
  STAFF_COMPANY_LIST,
  STAFF_COMPANY_CREATE,
  STAFF_COMPANY_GET,
  STAFF_COMPANY_EDIT,
  STAFF_COMPANY_ADVISOR_LIST,
  STAFF_COMPANY_ADVISOR_CREATE,
  STAFF_COMPANY_ADVISOR_GET,
  STAFF_COMPANY_ADVISOR_EDIT
} from 'constants/actions';

const initialState = {
  list: [],
  currentCompany: {},
  meta: {},
  advisorList: [],
  advisorMeta: {},
  currentAdvisor: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STAFF_COMPANY_LIST:
      return { ...state, list: action.data?.data || [], meta: action.data?.meta || {} };
    case STAFF_COMPANY_CREATE:
      return {
        ...state,
        list: [...state.list, action.data],
        meta: { ...state.meta, count: state.meta.count + 1 }
      };
    case STAFF_COMPANY_EDIT:
      return {
        ...state,
        list: state.list.map(company => {
          if (company.id === action.data.id) return action.data;
          return company;
        })
      };
    case STAFF_COMPANY_GET:
      return {
        ...state,
        currentCompany: action.data,
        list: state.list.map(company => {
          if (company.id === action.data.id) return action.data;
          return company;
        })
      };
    case STAFF_COMPANY_ADVISOR_LIST:
      return {
        ...state,
        advisorList: action.data?.data || [],
        advisorMeta: action.data?.meta || {}
      };
    case STAFF_COMPANY_ADVISOR_CREATE:
      return {
        ...state,
        advisorList: [...state.advisorList, action.data],
        advisorMeta: { ...state.advisorMeta, count: state.advisorMeta.count + 1 }
      };
    case STAFF_COMPANY_ADVISOR_GET:
      return {
        ...state,
        currentAdvisor: action.data,
        advisorList: state.advisorList.map(advisor => {
          if (advisor.id === action.data.id) return action.data;
          return advisor;
        })
      };
    case STAFF_COMPANY_ADVISOR_EDIT:
      return {
        ...state,
        advisorList: state.advisorList.map(advisor => {
          if (advisor.id === action.data.id) return action.data;
          return advisor;
        })
      };
    default:
      return state;
  }
};
