import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import 'react-table/react-table.css';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import {
  ADVISORS,
  CANCEL,
  SAVE,
  ROLE_OPTIONS,
  SUCCESS_CHANGES
} from 'containers/staff-companies/constants';
import SelectWrapper from 'containers/staff-companies/selectWrapper';
import 'containers/staff-companies/styles.scss';
import { VerboseErrorInput } from 'components/form';
import { AdvisorContext } from 'containers/advisor';
import { AuthenticationContext } from 'containers/auth';

const DisplayToast = (res, companyId, router) => {
  if (res.error) {
    const errorMsg = res.error.message;

    const errorsStr = Object.entries(res.error.errors)
      .map(([error, desc]) => `${error}: ${desc}`)
      .join('\n');

    toast.error(() => <div>{`${errorMsg}\n${errorsStr}`}</div>);
  } else {
    toast.success(() => <div>{SUCCESS_CHANGES}</div>);
    router.push(`/advisor/companies/${companyId}`);
  }
};

const EditableAdvisor = ({
  params: { companyId, advisorId },
  handleSubmit,
  initializeForm,
  fields
}) => {
  const { staffCompaniesProvider } = useContext(AdvisorContext);
  const { routerActions } = useContext(AuthenticationContext);

  useEffect(() => {
    if (advisorId !== 'create')
      staffCompaniesProvider.getAdvisor(companyId, advisorId).then(res => {
        if (!res.error) {
          const { first_name: firstName, last_name: lastName, email, phone, role } = res.data;
          initializeForm({
            firstName,
            lastName,
            email,
            phone,
            role
          });
        }
      });
  }, [companyId]);

  const formSubmit = values => {
    const { firstName, lastName, email, phone, role } = values;

    const advisor = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      role
    };

    const action = advisorId === 'create' ? 'createAdvisor' : 'editAdvisor';
    if (advisorId !== 'create') advisor.id = advisorId;

    staffCompaniesProvider[action](companyId, advisor)
      .then(res => DisplayToast(res, companyId, routerActions))
      .catch(error => {
        toast.error(() => <div>{error.message}</div>);
      });
  };

  return (
    <div className="staff-companies" style={{ marginTop: '26px' }}>
      <div className="h2">
        <h2>{ADVISORS.substring(0, ADVISORS.length - 1)}</h2>
      </div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div className="container-fluid table-white">
          <div className="row">
            <div className="col-sm-6">
              <VerboseErrorInput
                {...fields.firstName}
                label="First Name*"
                type="text"
                name="firstName"
                placeholder="First Name"
                className="form-control"
              />
            </div>
            <div className="col-sm-6">
              <VerboseErrorInput
                {...fields.lastName}
                label="Last Name*"
                type="text"
                name="lastName"
                placeholder="Last Name"
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <VerboseErrorInput
                {...fields.email}
                label="Email*"
                type="email"
                name="email"
                placeholder="Email"
                className="form-control"
              />
            </div>
            <div className="col-sm-6">
              <VerboseErrorInput
                {...fields.phone}
                label="Phone"
                type="text"
                name="phone"
                placeholder="Phone"
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <SelectWrapper
                styles={{ width: '100%' }}
                {...fields.role}
                header="Role*"
                options={ROLE_OPTIONS}
              />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link to={`/advisor/companies/${companyId}`}>
            <button type="button" className="btn btn-outline-primary button-min-width">
              {CANCEL}
            </button>
          </Link>
          <button type="submit" className="btn btn-primary button-min-width">
            {SAVE}
          </button>
        </div>
      </form>
    </div>
  );
};

EditableAdvisor.propTypes = {
  params: PropTypes.shape({
    companyId: PropTypes.string.isRequired,
    advisorId: PropTypes.string.isRequired
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    firstName: PropTypes.object.isRequired,
    lastName: PropTypes.object.isRequired,
    email: PropTypes.object.isRequired,
    phone: PropTypes.object.isRequired,
    role: PropTypes.object.isRequired
  }).isRequired
};

export default reduxForm({
  form: 'editableStaffCompanyAdvisor',
  fields: ['firstName', 'lastName', 'email', 'phone', 'role'],
  initialValues: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: ''
  }
})(EditableAdvisor);
